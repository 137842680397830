import React from 'react';
import Header from './Header';
import Footer from './Footer'

interface LayoutProps {
    children: React.ReactNode;
}


const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (

        <div className="bg-zinc-900	 text-white min-h-screen flex flex-col items-center">
            <div className='w-2/5'>

                <header>
                    <Header />
                </header>
                <div className='container mx-auto'>
                    <main>
                        {children}
                    </main>
                </div>
                <footer>
                    <Footer />
                </footer>

            </div>
        </div>
    );
};

export default Layout;