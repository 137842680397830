import React, { useRef, useState } from 'react'
import { Modal } from '@mui/material';

function importAll(r: any) {
    return r.keys().map(r);

}


const Desk = () => {

    // TODO: Sort images by date 


    const image_paths = importAll(require.context('../../public/desk/', false, /\.(png|jpe?g|svg|HEIC)$/))


    const images = image_paths.map((image: any) => {
        var img = new Image(image);
        img.src = image;

        return {
            src: image,
            alt: image.split('/')[image.split('/').length - 1].split('.')[0],
            height: img.height,
            width: img.width
        }
    }).reverse()

    const columns = 3
    var row_count = Math.ceil(images.length / columns) - 3
    var sorted_images = []

    for (var j = 0; j < row_count; j++) {
        for (var i = 0; i < columns; i++) {
            // console.log(j*columns + i," numarali foto", i, "suton", j, "satir","index:", i*row_count + j, "src", images[j*columns + i])
            sorted_images[i * row_count + j] = images[j * columns + i]
        }
    }
    console.log(sorted_images)




    const [modal, setModal] = useState(0)
    const [isOpened, setIsOpened] = useState(false)
    const cancelButtonRef = useRef(null)

    const handleClick = (index: number) => {
        console.log(index)
        setModal(index)
        setIsOpened(!isOpened)
    }


    return (
        <div>

            <div>
                <p className="font-sans py-5 text-justify ">
                    Galeride bir şeyler ararken masaların insanların --ya da sadece benim--  biyografilerini yanstığını fark ettim, o zamandan beri masalarımı fotoğraflamaya çalışıyorum.
                </p>
            </div>

            {/* <BentoCom  images={images.reverse()} handleClick={handleClick} columns={columns}/> */}
            <div className="columns-3 gap-6">
                {sorted_images.map((image: any, index: any) => (
                    <button
                        key={index}
                        className={`py-3 h-${image.height} break-inside-avoid rounded-xl dark:bg-neutral-900`}
                        onClick={() => handleClick(index)}
                    >
                        <img
                            src={image.src}
                            alt={image.alt}
                            className="rounded-lg"
                        />
                    </button>

                ))}
            </div>

            <Modal open={isOpened} onClose={() => setIsOpened(false)} ref={cancelButtonRef} className='flex justify-center items-center'>
                    <>
                    <button onClick={() => setModal((modal + 1) % sorted_images.length)} className="bg-black text-white p-2 rounded-lg">{"<"}</button>
                    {sorted_images[modal].height > sorted_images[modal].width ? (
                        <img src={sorted_images[modal].src} alt={sorted_images[modal].alt} className="rounded-2xl h-5/6" />
                        ) : (
                            <img src={sorted_images[modal].src} alt={sorted_images[modal].alt} className="rounded-2xl w-1/2" />
                            )}
                   Ï         <button onClick={() => setModal((modal + 1) % sorted_images.length
                     )} className="bg-black text-white p-2 rounded-lg">{">"}</button>
                            </>
            </Modal>


        </div>

    )
}

export default Desk