import React from 'react'

export const MENU = {
    "/": "Hoşgeldin",
    "/desk": "Masam",
    "/post": "Yazılar",
    // "/contact": "İletişim",
    "/resume": "CV",
  };
  
const Header = () => {
  return (
    <header className='py-10 text-white font-sans text-lg font-regular  '>
        <nav className='flex flex-row justify-between'>
            {Object.entries(MENU).map(([path, label]) => (
              <div>
                    <a href={path}>{label} </a>
              </div>
            ))}
            
        </nav>

    </header>



    )
}

export default Header