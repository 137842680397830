
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GithubIcon from '@mui/icons-material/GitHub';


const Social = () => {
    return (
        <div className='justify-center flex flex-row gap-4 opacity-50 text-2xl'>
            <a href="https://www.youtube.com/@enscngvn">
                <YouTubeIcon />
            </a>

            <a href="https://www.instagram.com/enscngvn">
                <InstagramIcon />
            </a>
            <a href="https://www.linkedin.com/in/guvenenes/">
                <LinkedInIcon />
            </a>
            <a href="https://twitter.com/enscngvn">
                <TwitterIcon />
            </a>
            <a href="https://github.com/enescanguven">
                <GithubIcon />
            </a>
        </div>
    )
}

export default Social