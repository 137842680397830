import React, { useState } from 'react'

interface PostProps {
    data: {
        title: string,
        description: string,
        date: string,
        read: string,
        img: string,
        lang: string,
        url: string
    }
}

const Post = ({ data }: PostProps) => {


    const [posts, setPosts] = useState(data)

    const handleClick = () => {
        window.open(data.url)

    }

    return (
        <div >
            <div className=" h-40 flex">
                <div className='w-40 flex p-2'>
                    <img src={data.img} alt="post" className='object-cover rounded-lg object-center' />
                </div>

                <div className="m-4 px-4 w-3/4 button-shadow rounded-lg flex flex-col justify-between  duration-300 ease-in-out"
                    onClick={handleClick}>

                    <p className="text-2xl font-bold no-underline hover:underline ">{data.title}</p>
                    <p className="text-gray-500 ">{data.description}</p>

                    <div className="m-0 p-0 flex flex-row space-x-4 text-gray-500">
                        <div className='flex space-x-2'>
                            <p>🗓️</p>
                            <p>April 10, 2024</p>
                        </div>
                        <div className='flex space-x-2'>
                            <p>📖</p>
                            <p>5 min read</p>
                        </div>
                        <div className='flex space-x-2'>
                            <p>{data.lang === 'tr' ? '🇹🇷' : '🇬🇧'} </p>
                            </div>

                    </div>
                </div>

            </div>
        
            <hr className='border-gray-500' />


        </div>


    )
}

export default Post