import Home from './pages/Home';
import Desk from './pages/Desk';
import Posts from './pages/Posts';

const routes = [
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/post",
        element: <Posts/>,
    },
    {
        path: "/contact",
        element: <div><a href="https://www.linkedin.com/in/guvenenes/" target="_blank" rel="noopener noreferrer">Open LinkedIn Profile</a></div>,
    },
    {
        path: "/desk",
        element: <Desk/>,
    }
]

export default routes;
