import Social from "../components/Social";

function Home() {

  return (
    <div>
      <div className=" mx-auto font-mono">
        <div className="font-sans font-bold text-xl">
          Enes Can Guven
          <p className="font-sans font-normal text-lg opacity-50">
            Hobby Engineer
          </p>
        </div>

        <p className="font-sans pt-5 text-justify ">
          Aslinda, faturayı ödemeyi unutup tüm yazılar silindikten sonra bir daha blogu canlandırmamaya kara vermiştim ama tekrar burdayım.
        </p>
        <p className="font-sans py-5 text-justify ">
          Henüz 11-12 yaşlarındayken elektroniğe, teknolojiye, bilgisayara, uzaya, uçaklara meraklı bir çocuk olarak kısıtlı Türkçe kaynakların arasından sıyrılmaya çalışıp internetin ayrıştırılmamış bilgi çöplüğünde sörf yaparken kişisel bir bloga denk geldim. Aylarca bu siteye her gün girdim, okudum, öğrendim, denedim, yaptım geliştirdim. Birinin projelerini arşivlemek için oluşturduğu bir sitenin, bir yerlerde birilerinin hayallerine dokunduğunu gördüm ve bunun ne kadar önemli olduğunu o zaman anladım. İşte bu site de bunu fark eden bir çocuğun yaptığı birkaç projeyi arşivlemek ve belki de gelecekte, bir yerlerde birilerinin hayatına ufak bir dokunuş için açtığı blogdur. Ayrıca melihkarakelle.com gibi daha pek çoklarının yaptığı ufak gibi görünen büyük işlere bir saygı duruşudur.
        </p>
        
      <div className="flex flex-row justify-start">
      <Social />

      </div>
      <img src="./chatwithenes.png" alt="Enes Can Guven" className="mx-auto mt-5 cursor-pointer rounded-lg"
       onClick={() => window.open("https://chat.enescanguven.com")} />
      </div>
      <div className="py-10">
      
      <img src="./me.jpeg" alt="Enes Can Guven" className="mx-auto" />



      </div>
    </div>
  );
}

export default Home;
